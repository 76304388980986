<template>
  <section :class="`detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar v-show="!back || deviceType==='desktop'" :title="domainConfig['IAmTitle']" />
    <BackHead v-show="back && deviceType!=='desktop'" :title="domainConfig['IAmTitle']" />
    <div class="content-wrapper">
      <slot />
    </div>
    <FootBar />
  </section>
</template>

<script>
import BackHead from './back_head.vue'

export default {
  components: {
    BackHead
  },

  props: {
    // title: {
    //   type: String,
    //   required: false,
    //   default: 'Quran Growth'
    // },
    back: {
      type: Boolean,
      default: false,
      require: false
    }
  },

};
</script>